
export interface ApiResponse {
    status: Status;
    data: any;

}
export interface Status {
    code: ApiResponseStatus;
    message: string;

    
}
export enum ApiResponseStatus {
        Success = 200,
        UnprocessableEntity = 422,
        Failer = 301,
        Logout = 305
  }