import { ApiResponse } from '@/Models/Response';
import store from '@/store';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Vue from 'vue';
export class HttpClient extends Vue {
   async post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>{
    store.commit("ChangeLoading", true);
        return Vue.axios.post(url, data, config).then((response) => {
            return response.data;
        })
            .catch((error) => {
                let err = <ApiResponse>error.response.data;
                this.$bus.$emit('testing_call',err);
                store.commit("ChangeLoading", false);
            });
            };
    
   async get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<T>{
    store.commit("ChangeLoading", true);
        return Vue.axios.get(url, config).then((response) => {
            return response.data;
        })
            .catch((error) => {
                let err = <ApiResponse>error.response.data;
                this.$bus.$emit('testing_call',err);
                store.commit("ChangeLoading", false);
            });
            };
    }
