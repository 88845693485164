import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../Modules/Auth/View/Login.vue'),
    meta: { requiredLogin: false }
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('../Modules/Auth/View/Logout.vue')
      },
    {
        path: '/admin',
        component: () => import('../Modules/Share/Layout.vue'),
        children: [
          {
            path: '',
            name: 'Dashboard',
            component: () => import('../Modules/Share/Dashboard.vue'),
            meta: { requiredLogin: true }
        },

        //partner start
            {
                path: '/partner/order',
                name: 'Partner-order',
                component: () => import('../Modules/Partner/Order.vue'),
                meta: { requiredLogin: true }
            },
            {
              path: '/partner/promo-code',
              name: 'Partner-code',
              component: () => import('../Modules/Partner/Promocode.vue'),
              meta: { requiredLogin: true }
          }, {
            path: '/partner/student',
            name: 'Partner-student',
            component: () => import('../Modules/Partner/Student.vue'),
            meta: { requiredLogin: true }
        },
        // end partner

        // start sales
        {
          path: '/sales/order',
          name: 'sales-order',
          component: () => import('../Modules/Sales/Order.vue'),
          meta: { requiredLogin: true }
          
      },
      {
        path: '/sales/live-class',
        name: 'sales-l-class',
        component: () => import('../Modules/Sales/Live Class.vue'),
        meta: { requiredLogin: true }
    },{
      path: '/sales/promocode',
      name: 'sales-promocode',
      component: () => import('../Modules/Sales/Promocode.vue'),
      meta: { requiredLogin: true }
      
  }
  ,{
    path: '/sales/feedback',
    name: 'sales-feedback',
    component: () => import('../Modules/Sales/Feedback.vue'),
    meta: { requiredLogin: true }
    
} ,{
  path: '/sales/students',
  name: 'sales-student',
  component: () => import('../Modules/Sales/Students.vue'),
  meta: { requiredLogin: true }
  
},
      // end sales

      // start marketing
      {
        path: '/market/demo-class',
        name: 'market-d-class',
        component: () => import('../Modules/Marketing/Demo Class.vue'),
        meta: { requiredLogin: true }
    },

      // end marketing
      // School Offering
      {
        path: '/school/callback-request',
        name: 'school-call-request',
        component: () => import('../Modules/School/Request.vue'),
        meta: { requiredLogin: true }
    },
    // end school offering

    //teacher start
          {
          path: '/teacher/enroll',
          name: 'teacher-enroll',
          component: () => import('../Modules/Teacher/Student.vue'),
          meta: { requiredLogin: true }
          },
          {
            path: '/teacher/demo-class',
            name: 'teacher-demo-class',
            component: () => import('../Modules/Teacher/Demo Class.vue'),
            meta: { requiredLogin: true }
            },
            {
              path: '/teacher/live-class',
              name: 'teacher-live-class',
              component: () => import('../Modules/Teacher/Live Class.vue'),
              meta: { requiredLogin: true }
              },
              {
                path: '/teacher/feedback',
                name: 'teacher-feedback',
                component: () => import('../Modules/Teacher/Feedback.vue'),
                meta: { requiredLogin: true }
                }
                ,
              {
                path: '/teacher/assignment',
                name: 'teacher-assignment',
                component: () => import('../Modules/Teacher/Assignment.vue'),
                meta: { requiredLogin: true }
                },
    //end teacher 
    //role manager start
    {
      path: '/role/user',
      name: 'role-user',
      component: () => import('../Modules/RoleManager/Users.vue'),
      meta: { requiredLogin: true }
      },
    //role manager end
    //Teacher manager start
    {
      path: '/admin/teacher/courses',
      name: 'teacher-admin-course',
      component: () => import('../Modules/TeacherManager/Courses.vue'),
      meta: { requiredLogin: true }
      },
      {
        path: 'admin/teacher',
        name: 'teacher-admin-list',
        component: () => import('../Modules/TeacherManager/Teachers.vue'),
        meta: { requiredLogin: true }
        },
        {
          path: 'admin/teacher/enroll',
          name: 'teacher-admin-student-list',
          component: () => import('../Modules/TeacherManager/EnrollCourse.vue'),
          meta: { requiredLogin: true }
          }
    //Teacher manager end

    // Content Admin start
    ,
    {
      path: 'Content/data',
      name: 'content-data',
      component: () => import('../Modules/Content/Content.vue'),
      meta: { requiredLogin: true }
      },
      {
        path: 'Content/question',
        name: 'content-question',
        component: () => import('../Modules/Content/Question.vue'),
        meta: { requiredLogin: true }
        }
    // content admin end
        ]
    }
   
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "exact-active-link",
})
router.beforeEach((to, from, next) => {
  store.commit("ChangeLoading",true)
  if(to.meta.requiredLogin!=null){
    if (to.meta.requiredLogin) {
      if (store.state.User==null) next({ name: 'Login' });
      else next();
  }
  else {
      if (store.state.User!=null) next({ name: 'Dashboard'});
      else next();
  }
  }
  else next();
})
router.afterEach(()=> {
  store.commit("ChangeLoading",false)
})
export default router
