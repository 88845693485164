import store from "@/store";
import moment from "moment";
export class EncHelper{
static getToken(){
    var JSEncrypt = require("jsencrypt/lib/JSEncrypt");
    let token = store.state.User?.token;
    let date = moment.utc().format("DDMMYYYYHHmmss");
    let f = token+":"+date;
    let t = new  JSEncrypt.JSEncrypt();
    t.setPublicKey(`-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuJh9BErEXoefzX4g5Kjy
    8dWOsxrHOJfUCjWfaiF3oElEFRerCKtxQXG+rQj9wCDgaUtdq93o9T3YM/qR+8VM
    Sra5Obss5+7zPB/G3/DjagqDFNRDn9Qnl4du6XISNE26yqIuY2tru+qI3EEGs9XR
    QGKvL4Cn+E1GNghlgO59wZJ6rM8icZgZnPOEHg4MXC7HzMSY1Vcdm9rDrMYQii1/
    aJzIH6qYktjvXqmusWmrUrPDfeLdByLPOKUSJjETLRIMKOBsiRr8toWJRSeLpsqn
    dz6tatAdhGmsIZ7cLYeO3ZxtsS0foXrE1gel0t/HmTdMcw501c3vDjeopHpjs2Fr
    QwIDAQAB
    -----END PUBLIC KEY-----`);
    let data = t.encrypt(f);
    return data;
 }
}