
















































































    import { Component, Vue,Ref } from 'vue-property-decorator'
    import { ToastPlugin, ToastCloseArgs } from "@syncfusion/ej2-vue-notifications";
import { ApiResponse } from './Models/Response';
    Vue.use(ToastPlugin);
    
@Component
export default class App extends Vue {
    @Ref() readonly toastRef!: any
    position = { X: 'Right' };
toasts = [
                { title: 'Warning!', content: 'There was a problem with your network connection.', cssClass: 'e-toast-warning', icon: 'e-warning toast-icons' },
                { title: 'Success!', content: 'Your message has been sent successfully.', cssClass: 'e-toast-success', icon: 'e-success toast-icons' },
                {  content: 'A problem has been occurred while submitting your data.', },
                { title: 'Information!', content: 'Please read the comments carefully.', cssClass: 'e-toast-info', icon: 'e-info toast-icons' }];
    mounted () 
    { 
        
        console.log("Mounted");
   this.$bus.$on('testing_call',(data: ApiResponse) =>{
            
              this.toastRef.show({
                  title: 'Error!',
                  content: data.data,
                  cssClass: 'e-toast-danger', icon: 'e-error toast-icons' 
              });
        })
}
    
    
}
