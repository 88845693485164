import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import {User} from "./../Models/User"
import { Store } from '@/store/Store';
import { IpInfo } from '../Models/IpInfo';
Vue.use(Vuex)
var ls = new SecureLS({ isCompression: true });

const store: StoreOptions<Store> = {
  plugins: [createPersistedState({
    storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
    },
}),],
  state: {
      User:null,
      Ipinfo: null,
      Loading: false,
  },
  modules: {
  },
  actions:{
    ChangeUser(context, _user: User) {
      context.commit('ChangeUser', _user);
      },
      ChangeLoading(context, _loading: boolean) {
          context.commit('ChangeLoading', _loading);
      },
    setIpInfo(context,_ip:IpInfo){
      context.commit('setIpInfo',_ip);
    }
  },
  mutations:{
    ChangeUser(state, _user: User) {
      state.User = _user;
    },
    setIpInfo(state,_ip:IpInfo){
      state.Ipinfo = _ip;
      },
      ChangeLoading(state, _loading: boolean) {
          state.Loading = _loading;
      }
  }
};
export default new Vuex.Store<Store>(store);

