import Vue from 'vue'
import App from './App.vue'
import { WebApi } from './Helper/WebApi'
import './registerServiceWorker'
import router from './router'
import store from './store'
import browserDetect from "vue-browser-detect-plugin";
import axios from 'axios'
import VueAxios from 'vue-axios'
import EventBus from 'vue-bus-ts';
Vue.use(browserDetect);
Vue.use(VueAxios, axios)
Vue.config.productionTip = true;
var ip = WebApi.GetIpInfo();
Vue.use(EventBus);
var bus = new EventBus.Bus();
ip.then(d=>{

  store.commit("setIpInfo",d);
})
new Vue({
  bus,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

